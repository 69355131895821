body {
  margin: 0%;
  padding: 0%;
}

#body {
  background-color: black;
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.displayNone {
  display: none;
}
.displayBlock {
  display: block !important;
}
.displayFlex {
  display: flex;
}

/*  */
/* header */
/*  */

header {
  height: 10vh;
  width: 100%;
  font-family: "kaushan script";
  background-color: #2f75a6;
  position: fixed;
  top: 0;
}

/*  */
/* logo */
/*  */

#logo {
  height: 10vh;
  display: flex;
  background-color: #2f75a6;
  color: #000000;
  animation: headerMinimize 5s linear 1;
  align-items: center;
  float: left;
}

@media screen and (min-width: 825px) {
  #logo {
    justify-content: flex-start;
    width: 35vw;
  }
  @keyframes headerMinimize {
    0% {
      height: 100vh;
      width: 100vw;
      letter-spacing: 0px;
      justify-content: center;
    }
    20% {
      letter-spacing: 25px;
      height: 100vh;
      width: 100vw;
    }
    40% {
      height: 100vh;
      width: 100vw;
      letter-spacing: 0px;
    }
    80% {
      height: 10vh;
      width: 100vw;
      justify-content: center;
    }
    100% {
      justify-content: flex-start;
      width: fit-content;
    }
  }
}

@media screen and (max-width: 825px) {
  #logo {
    justify-content: center;
    width: 100vw;
  }
  @keyframes headerMinimize {
    0% {
      height: 100vh;
      width: 100vw;
      letter-spacing: 0px;
      justify-content: center;
    }
    20% {
      letter-spacing: 8px;
      height: 100vh;
      width: 100vw;
    }
    40% {
      height: 100vh;
      width: 100vw;
      letter-spacing: 0px;
    }
    80% {
      height: 10vh;
      width: 100vw;
      justify-content: center;
    }
    100% {
      justify-content: center;
      /* width: 90vw; */
    }
  }
}

/*  */
/* h1 */
/*  */

h1 {
  margin: 0%;
}

@media screen and (min-width: 950px) {
  h1 {
    font-size: 45px;
  }
}
@media screen and (max-width: 950px) {
  h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 35px;
  }
}
@media screen and (max-width: 350px) {
  h1 {
    font-size: 25px;
  }
}

/*  */
/* navbar */
/*  */

.navbarIconContainer {
  display: inline-block;
  cursor: pointer;
}

.navbarIconBar1,
.navbarIconBar2,
.navbarIconBar3 {
  width: 35px;
  height: 5px;
  background-color: #000000;
  margin: 6px 0;
  transition: 0.4s;
}

.navbarIconChange .navbarIconBar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.navbarIconChange .navbarIconBar2 {
  opacity: 0;
}

.navbarIconChange .navbarIconBar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

nav,
nav a {
  color: #000000;
  text-decoration: none;
}

nav div pre span {
  font-family: "kaushan script";
}

@media screen and (min-width: 825px) {
  div#navbarIcon {
    display: none;
  }
  div#navSmall {
    display: none;
  }
  div#navFull {
    height: 10vh;
    width: 64vw;
    display: none;
    float: right;
    align-items: center;
    justify-content: flex-end;
    background-color: #2f75a6;
  }
  div#navFull nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  div#navFull nav a div,
  div#navFull nav div {
    /* width: 10vw; */
    height: 8vh;
    float: left;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    /* background-color: aqua; */
  }
  .modeDiv {
    margin-right: 15px;
  }
  div#navFull nav div #modeBtn {
    width: 20px;
    height: 20px;
    background-color: white;
  }
  div#navFull nav div#navItem:hover {
    box-shadow: 2px 2px 5px #ffffff;
  }
}
@media screen and (max-width: 825px) {
  div#navbarIcon {
    height: 10vh;
    width: 20vw;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    float: right;
  }
  div#navFull {
    display: none;
  }
  div#navSmall {
    height: 91vh;
    width: 100vw;
    margin-top: 9vh;
    background-color: #2f75a6;
    position: absolute;
  }
  div#navSmall nav div {
    width: 100vw;
    height: 10vh;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  div#navSmall nav div #modeBtn {
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 15px;
  }
}

/*  */
/* Main */
/*  */

#main {
  color: #2f75a6;
  margin-top: 10vh;
}

main#main section.mainSections {
  width: 98vw;
  margin-left: 0.5vw;
}

h2 {
  text-align: center;
  font-family: "kaushan script";
}

.experienceContentDetails h3,
.experienceContentDetails h4 {
  text-align: center;
  text-decoration: underline;
}

.experienceContentDetails h3 {
  font-family: "kaushan script";
}

.openSourceContent {
  background-color: #555;
  border-radius: 25px;
}

#Project1 {
  width: 50%;
}

#Project2 {
  width: 70%;
}

#Project3 {
  width: 40%;
}

#Project1,
#Project2,
#Project3 {
  border-radius: 25px 0 0 25px;
  padding: 5px 0;
  background-color: #2f75a6;
  color: #f1f1f1;
  text-align: center;
  font-family: "kaushan script";
}

.achievementsContent {
  width: 98vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#achievementsContentDiv hr {
  width: 49vw;
  box-shadow: #2f75a6 1px 1px 5px;
}

.achievementsLogo {
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.achievementsLogo img {
  width: 50%;
}

.achievementsDetails {
  width: 80vw;
}

.achievementsDetails h3,
.achievementsDetails h4 {
  font-family: "kaushan script";
}

#myImageDiv {
  width: 49vw;
}

#myImageDiv h2 {
  text-align: left;
}

#myImage {
  width: 200px;
  height: 200px;
  border: 5px solid #2f75a6;
  border-radius: 100px;
}

.contactLinksDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactLinksImsgs a {
  color: #2f75a6;
  font-size: xx-large;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 5px 0;
}

.contactLinksImsgs:hover {
  box-shadow: #2f75a6 2px 2px 5px;
}

.ContactButtons {
  margin: 15px 0;
}

.ContactButtons a {
  cursor: pointer;
}

.ContactButtons button {
  background-color: #000000;
  border: 0px;
  color: #2f75a6;
  margin: 0px 15px;
  box-shadow: #2f75a6 2px 2px 5px;
  text-decoration: none;
  cursor: pointer;
  font-size: large;
  font-family: "kaushan script";
}

.ContactButtons button:disabled {
  box-shadow: none;
}

.ContactButtons button:disabled .FaDownload {
  display: none;
}

.ContactButtons button:hover {
  box-shadow: none;
}
#myImageDiv {
  width: 98vw;
  text-align: center;
}
#myImageDiv h2 {
  text-align: center;
}
.ContactButtons {
  text-align: center;
}

@media screen and (min-width: 825px) {
  .SectionsContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #introImageDivFull {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49vw;
  }
  #skillsImageDiv div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49vw;
  }
  #skillsImageDiv div p {
    text-align: center;
  }
  #introImageDivFull img,
  #skillsImageDiv img {
    width: 50%;
  }
  #introImageDivSmall {
    display: none;
  }
  #introContentDiv,
  #skillsContentDiv {
    width: 49vw;
  }
  #experience {
    width: 98vw;
  }
  #experienceContentDiv {
    height: max-content;
    width: 98vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .experienceContent {
    width: 31vw;
    margin-right: 1vw;
    margin-left: 1vw;
    cursor: pointer;
  }
  .experienceContent:hover {
    box-shadow: #2f75a6 2px 2px 5px;
  }
  #openSourceContentDiv {
    height: max-content;
    width: 98vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .openSourceContent {
    width: 23vw;
    margin-right: 1vw;
    cursor: pointer;
  }
  .openSourceContent:hover {
    box-shadow: #2f75a6 2px 2px 5px;
  }
  #contactInfo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #contactContents {
    width: 49vw;
  }
  .contactLinksDiv {
    width: 49vw;
  }
  .contactLinksImsgs {
    width: 7vw;
  }
}
@media screen and (max-width: 825px) {
  #introImageDivFull {
    display: none;
  }
  #introImageDivSmall {
    display: block;
  }
  #introImageDivSmall img,
  #skillsImageDiv img {
    width: 98%;
    height: 49%;
  }
  .openSourceContent {
    margin: 15px 0;
  }
  #contactContents {
    width: 100vw;
  }
  .contactLinksDiv {
    width: 98vw;
  }
  .contactLinksImsgs {
    width: 14vw;
  }
}

/*  */
/* footer */
/*  */

#footer {
  height: 4vh;
  color: #2f75a6;
  font-family: "kaushan script";
  text-align: center;
}
